import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { keyframes } from "@emotion/react";

const RoundedWave = ({ progress }: { progress: number }) => {
    const [completed, setCompleted] = useState<number>(progress);
    useEffect(() => {
        setCompleted(progress / 2 + 54);
    }, [progress]);

    const animate = keyframes`
  0%{
            transform: translate(-50%, -${completed}%) rotate(0deg);
        }
        100%{
            transform: translate(-50%, -${completed}%) rotate(360deg);
        }
`;
    return (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "6rem",
                height: "6rem",
                background: "#ccc",
                // border: "3px solid #4973ff",
                borderRadius: "50%",
                overflow: "hidden",
            }}>
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    background: "#4973ff",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 50px rgba(0, 0, 0, 0.5)",
                    "&:before": {
                        content: "''",
                        position: "absolute",
                        width: "200%",
                        height: "200%",
                        top: "0",
                        left: "50%",
                        transform: "translate(-50%, -75%)",
                        borderRadius: "45%",
                        background: "rgba(255, 255, 255, 1)",
                        animation: `${animate} 5s linear infinite`,
                    },
                    "&:after": {
                        content: "''",
                        position: "absolute",
                        width: "200%",
                        height: "200%",
                        top: "0",
                        left: "50%",
                        transform: "translate(-50%, -75%)",
                        borderRadius: "40%",
                        background: "rgba(255, 255, 255, .5)",
                        animation: `${animate} 10s linear infinite`,
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Typography sx={{ zIndex: 1 }}>{progress}</Typography>
            </Box>
        </Box>
    );
};

export default RoundedWave;
