import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Folder from "../../Icons/Folder";
import { Api } from "../../apis";
import moment from "moment";
import { Context } from "../../store/store";
import Bounce from "../Loader/Bounce";
import { useNavigate } from "react-router-dom";

const Translate = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [files, setFiles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    useEffect(() => {
        setLoader(true);
        fetchDocuments();
    }, []);

    const fetchDocuments = () => {
        Api?.getTranslationList()
            ?.then((res: any) => {
                setLoader(false);
                if (res?.status === 200) {
                    setFiles(res?.data?.files);
                } else {
                    setFiles([]);
                }
            })
            ?.catch((err) => {
                setLoader(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Fetch Documents",
                    },
                });
            });
    };

    const uploadDocument = (file: any) => {
        const formData = new FormData();
        formData.append("file", file);
        Api?.uploadCliamsTranslationFile(formData)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "File Ingested",
                        },
                    });
                    setLoader(true);
                    fetchDocuments();
                }
            })
            ?.catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Upload Documents",
                    },
                })
            );
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1, width: "100%", background: "#FEFDFD" }}>
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                    width: "50rem",
                    height: "90%",
                    pb: 3,
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                    gap: 1,
                }}>
                <Typography sx={{ color: "#007BFF", fontSize: "1.2rem", fontWeight: 550 }}>Translate your Documents</Typography>
                <Typography variant="caption" sx={{ color: "#9D9B9B" }}>
                    Just upload and convert your documents to any language with ease.
                </Typography>
                <Stack alignItems={"center"}>
                    <Button
                        sx={{
                            my: 1.5,
                            minWidth: "9.5rem",
                            background:
                                "linear-gradient(233.41deg,rgba(242, 245, 249, 1) 0.2%,rgba(250, 252, 255, 1) 48.8%,rgba(223, 239, 255, 1) 96.4%)",
                            borderRadius: "14px",
                            boxShadow: "-0.1875rem 0.1875rem 0rem 0rem rgba(0, 0, 0, 0.12)",
                        }}
                        component={"label"}>
                        <input hidden type="file" accept=".pdf" onChange={(e: any) => uploadDocument(e?.target?.files[0])} />
                        Upload
                    </Button>
                </Stack>
                <Stack px={3} flex={1}>
                    <Typography sx={{ fontWeight: 550, fontSize: "0.95rem", py: 1.5 }}>Previously Uploaded</Typography>
                    <Stack sx={{ flex: "1 0 10vh", overflowY: "scroll", pr: 1 }}>
                        {loader ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "20rem",
                                }}>
                                <Bounce />
                            </Box>
                        ) : (
                            files?.map((item: any) => (
                                <Stack
                                    key={item?.file_identifier}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    m={1}
                                    p={1}
                                    onClick={() => {
                                        try {
                                            setContext({ ...contextStore, translationFile: item });
                                            navigate("/translate/report");
                                        } catch (error) {}
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        transition: "ease-in-out 200ms",
                                        "&:hover": {
                                            boxShadow: "-1px 1px 4px 0px #9D9B9B",
                                            px: 2,
                                        },
                                    }}>
                                    <Stack alignItems={"center"} direction={"row"} gap={1}>
                                        <Folder />
                                        <Typography sx={{ color: "#1C1B1F", maxWidth: "95%" }} noWrap>
                                            {item?.file_name}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="caption" sx={{ color: "#8D8D8D", whiteSpace: "pre" }}>
                                        {moment(item?.created_at, "YYYY-MM-DD hh-mm-ss")?.format("DD/MM/YYYY")} {item?.file_size}
                                    </Typography>
                                </Stack>
                            ))
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default Translate;
