import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Bounce from "../Loader/Bounce";
import FileIcon from "../../Icons/FileIcon";
import { Context } from "../../store/store";
import { Api } from "../../apis";

const Claims = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [projects, setProjects] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    useEffect(() => {
        if (contextStore?.profile?.userId) {
            setLoader(true);
            if (contextStore?.claims?.projects?.length > 0) {
                setProjects(() => contextStore?.claims?.projects || []);
                setLoader(false);
            } else {
                Api?.getClaimsExistingProjects({ user_id: contextStore?.profile?.userId })
                    ?.then((res: any) => {
                        if (res?.status === 200) {
                            setProjects(() => res?.data?.projects);
                            setContext({
                                ...contextStore,
                                claims: { ...contextStore?.claims, projects: res?.data?.projects },
                            });
                        }
                        setLoader(false);
                    })
                    ?.catch((err) => setLoader(false));
            }
        }
    }, [contextStore?.claims?.projects, contextStore?.profile?.userId]);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1, width: "100%", background: "#FEFDFD" }}>
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    p: 2,
                    width: "50rem",
                    height: "fit-content",
                    pb: 3,
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                }}>
                <Box>
                    <Typography sx={{ color: "#007BFF", fontSize: "1.07rem" }}>Start your Project</Typography>
                    <Typography sx={{ color: "#9D9B9B", fontSize: "0.9rem" }}>
                        Easily compare documents by uploading them and uncovering similarities in their data.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                    <Button
                        sx={{
                            color: "#007BFF",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            background:
                                "linear-gradient(233.41deg,rgba(242, 245, 249, 1) 0.20882596727460623%,rgba(250, 252, 255, 1) 48.81398379802704%,rgba(223, 239, 255, 1) 96.47764563560486%)",
                            boxShadow: "-0.1875rem 0.1875rem 0rem 0rem rgba(0, 0, 0, 0.12)",
                            borderRadius: "0.8rem",
                            px: 3,
                            py: 1,
                        }}
                        onClick={() => navigate("/claims/new_project")}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.375 14.2188H5.625C2.23125 14.2188 0.78125 12.7688 0.78125 9.375V5.625C0.78125 2.23125 2.23125 0.78125 5.625 0.78125H9.375C12.7688 0.78125 14.2188 2.23125 14.2188 5.625V9.375C14.2188 12.7688 12.7688 14.2188 9.375 14.2188ZM5.625 1.71875C2.74375 1.71875 1.71875 2.74375 1.71875 5.625V9.375C1.71875 12.2563 2.74375 13.2812 5.625 13.2812H9.375C12.2563 13.2812 13.2812 12.2563 13.2812 9.375V5.625C13.2812 2.74375 12.2563 1.71875 9.375 1.71875H5.625Z"
                                fill="#007BFF"
                            />
                            <path
                                d="M8.5999 11.0938H6.39365C5.70615 11.0938 5.1624 10.7562 4.85615 10.1437L4.2999 9.025C4.16865 8.75625 3.8999 8.59375 3.5999 8.59375H1.24365C0.987402 8.59375 0.774902 8.38125 0.774902 8.125C0.774902 7.86875 0.993652 7.65625 1.2499 7.65625H3.5999C4.25615 7.65625 4.84365 8.01875 5.1374 8.60625L5.69365 9.725C5.8374 10.0125 6.06865 10.1562 6.39365 10.1562H8.5999C8.8999 10.1562 9.16865 9.99375 9.2999 9.725L9.85615 8.60625C10.1499 8.01875 10.7374 7.65625 11.3937 7.65625H13.7312C13.9874 7.65625 14.1999 7.86875 14.1999 8.125C14.1999 8.38125 13.9874 8.59375 13.7312 8.59375H11.3937C11.0937 8.59375 10.8249 8.75625 10.6937 9.025L10.1374 10.1437C9.84365 10.7312 9.25615 11.0938 8.5999 11.0938Z"
                                fill="#007BFF"
                            />
                            <path
                                d="M8.54355 4.84375H6.4623C6.1998 4.84375 5.9873 4.63125 5.9873 4.375C5.9873 4.11875 6.1998 3.90625 6.45605 3.90625H8.5373C8.79355 3.90625 9.00605 4.11875 9.00605 4.375C9.00605 4.63125 8.7998 4.84375 8.54355 4.84375Z"
                                fill="#007BFF"
                            />
                            <path
                                d="M9.0625 6.71875H5.9375C5.68125 6.71875 5.46875 6.50625 5.46875 6.25C5.46875 5.99375 5.68125 5.78125 5.9375 5.78125H9.0625C9.31875 5.78125 9.53125 5.99375 9.53125 6.25C9.53125 6.50625 9.31875 6.71875 9.0625 6.71875Z"
                                fill="#007BFF"
                            />
                        </svg>
                        Create New Project
                    </Button>
                </Box>
                <Box sx={{ width: "80%", alignSelf: "center" }}>
                    <Typography sx={{ fontSize: "0.95rem", fontFamily: "Gilroy-SemiBold", py: 1.5 }}>Previously created</Typography>
                    {loader ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "45vh",
                            }}>
                            <Bounce />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                height: "53vh",
                                overflowY: "scroll",
                            }}>
                            {projects?.length > 0 ? (
                                projects?.map((project: any) => (
                                    <Box
                                        key={project?.name}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 0.3,
                                            cursor: "pointer",
                                            transition: "200ms ease-out",
                                            borderRadius: "6px",
                                            "&:hover": {
                                                boxShadow: "3px 3px 4px 1px #00000010",
                                                px: 1,
                                            },
                                        }}
                                        onClick={() => {
                                            try {
                                                setContext({
                                                    ...contextStore,
                                                    claims: { ...contextStore?.claims, activeProject: project },
                                                });
                                                navigate("/claims/reports");
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                                fontFamily: "Gilroy-Medium",
                                            }}>
                                            <FileIcon />
                                            {project?.name}
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                                            <Typography sx={{ color: "#8D8D8D", fontSize: "0.8rem" }}>
                                                {moment(project?.created_at)?.format("DD/MM/YY")}
                                            </Typography>
                                            <Typography sx={{ color: "#007BFF", fontSize: "0.8rem" }}>{project?.pdfs_count} Files</Typography>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "30%",
                                    }}>
                                    <Typography sx={{ color: "#8D8D8D" }}>Please Add a Project</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Claims;
