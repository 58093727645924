import { TableRow, TableCell, Box, IconButton, Typography, Button } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import Relevencies from "./Relevencies";
import { Api } from "../../../../apis";
import { Context } from "../../../../store/store";

const Chronology = ({ index, row, expand, tableRef }: { index: number; row: any; expand: boolean; tableRef: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [collapse, setCollapse] = useState<boolean>(true);
    useEffect(() => {
        setCollapse(expand);
    }, [expand]);

    const download = (url: string) => {
        Api?.downloadClaimFile({ blob_url: url })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    const a = document?.createElement("a");
                    a.hidden = true;
                    a.download = `Chronology ${row["Delay Event"]} ${row["Party"]} ${row["Sub Delay Event"]} ${
                        moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()
                    }`;
                    a.href = res?.data?.sas_url;
                    Promise?.resolve()
                        ?.then(() => {
                            tableRef.current.appendChild(a);
                            return true;
                        })
                        ?.then(() => {
                            a.click();
                            tableRef.current.removeChild(a);
                        })
                        ?.catch((err) => console.log(err));
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Download Document",
                        },
                    });
                }
            })
            ?.catch((err) => {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Download Document",
                    },
                });
            });
    };

    return collapse ? (
        <TableRow>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {index + 1}
            </TableCell>
            {["Delay Event", "Sub Delay Event", "Party", "Department"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))}

            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {row["Letter Reference No."]}
                </Typography>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()}
                </Typography>
            </TableCell>
            {["Referred"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))}

            {["Missing in Claims"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}
            {["Letter Type", "Response To Letter", "Response Letters", "Response Letters' Date", "Letter Response Time"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}

            {/* <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
        {row["Reason for letter"]?.toString()}
    </TableCell> */}
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                    {row["Gist"]}
                </Typography>
            </TableCell>

            {["Contract-Contrasts", "Clauses/Sub-Clauses in letter", "Relevancies"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]}
                    </Typography>
                </TableCell>
            ))}
            {["Contrasting Letter Number", "Letter Contrasts", "Clauses/Sub-Clauses in letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    <Typography noWrap sx={{ width: "8rem", fontSize: "0.9rem" }}>
                        {row[item]?.toString()}
                    </Typography>
                </TableCell>
            ))}
        </TableRow>
    ) : (
        <TableRow>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {index + 1}
            </TableCell>
            {["Delay Event", "Sub Delay Event", "Party", "Department"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))}

            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Letter Reference No."]
    ?.toString()
    ?.split("(")
    ?.map((str: string) =>
        str?.startsWith("http") ? (
            <>
                {"("}
                <a href={str?.replace(")", "")} target="_blank">
                    {str?.replace(")", "")}
                </a>
                {")"}
            </>
        ) : (
            str
        )
    )} */}
                {row["Letter Reference No."]?.toString()?.includes("http") ? (
                    <>
                        {"["}
                        <Button sx={{ minWidth: "32px" }} onClick={() => download(row["Letter Reference No."]?.split("(")[1]?.replace(")", ""))}>
                            {row["Letter Reference No."]?.split("[")[1]?.split("(")[0]?.replace("]", "")}
                        </Button>
                        {"]"}
                    </>
                ) : (
                    row["Letter Reference No."]
                )}
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {moment(row["Date"]?.toString())?.isValid() ? moment(row["Date"]?.toString())?.format("YYYY-MM-DD") : row["Date"]?.toString()}
            </TableCell>
            {["Referred"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))}
            {["Missing in Claims"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()}
                </TableCell>
            ))}
            {["Letter Type", "Response To Letter", "Response Letters", "Response Letters' Date", "Letter Response Time"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()}
                </TableCell>
            ))}

            {/* <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
        {row["Reason for letter"]?.toString()}
    </TableCell> */}
            <TableCell
                sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                size="small"
                dangerouslySetInnerHTML={{
                    __html: row["Gist"]
                        ?.toString()
                        ?.replace(row["Gist Contrasts"]?.toString(), `<span style="background:yellow; color:#000;">${row["Gist Contrasts"]}</span>`),
                }}></TableCell>

            {["Contract-Contrasts", "Clauses/Sub-Clauses in letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]
                        ?.toString()
                        ?.split("\n")
                        ?.map((subItem: string) => (
                            <>
                                {subItem}
                                <br />
                            </>
                        ))}
                </TableCell>
            ))}
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${row["Relevancies"]?.replaceAll("'", '"')}`} />
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${row["Contrasting Letter Number"]?.replaceAll("'", '"')}`} />
            </TableCell>
            <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                {/* {row["Relevancies"]?.toString()} */}
                <Relevencies value={`${row["Letter Contrasts"]?.replaceAll("'", '"')}`} />
            </TableCell>
            {["Clauses/Sub-Clauses in letter"]?.map((item) => (
                <TableCell sx={{ border: "1px solid #D9D9D9", p: 0.5 }} size="small">
                    {row[item]?.toString()?.split("\n")?.length > 1
                        ? row[item]
                              ?.toString()
                              ?.split("\n")
                              ?.map((subItem: string) => (
                                  <Box>
                                      <Box sx={{ display: "flex", gap: 1 }}>
                                          <div
                                              style={{
                                                  width: "5px",
                                                  height: "5px",
                                                  borderRadius: "50%",
                                                  background: "#000",
                                                  padding: "4px",
                                                  marginTop: "3px",
                                              }}></div>
                                          {subItem}
                                      </Box>
                                      <br />
                                  </Box>
                              ))
                        : row[item]
                              ?.toString()
                              ?.split("\n")
                              ?.map((subItem: string) => (
                                  <>
                                      {subItem}
                                      <br />
                                  </>
                              ))}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default Chronology;
