import React, { useContext, useEffect, useState } from "react";
import { Alert, Snackbar, Typography } from "@mui/material";
import { Context } from "../../store/store";

const SnackbarAlert = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [open, setOpen] = useState(true);
    useEffect(() => {
        setOpen(contextStore?.snackState?.open);
    }, [contextStore?.snackState?.open]);
    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={() => setContext((prev: any) => ({ ...prev, snackState: { open: false, errorType: "", message: "" } }))}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity={contextStore?.snackState?.errorType === "" ? "success" : contextStore?.snackState?.errorType}>
                <Typography sx={{ fontSize: "0.95rem", fontFamily: "Gilroy-SemiBold" }}>{contextStore?.snackState?.message}</Typography>
            </Alert>
        </Snackbar>
    );
};

export default SnackbarAlert;
