import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Divider, IconButton, MenuItem, Select, Stack, Switch, Tooltip, Typography, Zoom } from "@mui/material";
import { IconExclamationCircle, IconMinus } from "@tabler/icons-react";
import { Api } from "../../apis";
import { Page, Document, pdfjs } from "react-pdf";
import RoundedWave from "../Loader/RoundedWave";
import { Add, Download, Remove, ZoomIn, ZoomOut } from "@mui/icons-material";

const DocumentRepot = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    const navigate = useNavigate();
    const documentRef = useRef<any>();
    const pageRef: any = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [language, setLanguage] = useState<string>("English");
    const [padding, setPadding] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number>(1);
    const [pdf, setPdf] = useState<any>();
    const [translateLoading, setTranslateLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [originalLoad, setOriginalLoad] = useState<boolean>(true);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!contextStore?.translationFile) {
            navigate("/translate");
        } else {
            fetchPdf("original");
        }
    }, [contextStore?.translationFile]);

    const fetchPdf = (type: "original" | "translated") => {
        setLoader(true);
        if (type === "original") {
            setProgress(50);
        }
        Api?.getClaimsOriginalPdf({ pdf_name: contextStore?.translationFile?.file_identifier, type: type })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (type === "original") {
                        setProgress(100);
                    }

                    const blob = new Blob([res?.data], { type: "application/pdf" });
                    const url = URL.createObjectURL(blob);
                    setLoader(false);
                    setPdf(url);
                } else {
                    setPdf(null);
                    if (type === "original") {
                        setProgress(0);
                    }
                    if (type === "translated") {
                        setTranslateLoading(false);
                        setOriginalLoad(true);
                    }
                }
            })
            ?.catch((err) => {
                console.log(err);
                setPdf(null);
                if (type === "original") {
                    setProgress(0);
                }
                if (type === "translated") {
                    setTranslateLoading(false);
                    setOriginalLoad(true);
                }
                setLoader(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Fetch Document",
                    },
                });
            });
    };

    const translatePdf = () => {
        if (translateLoading) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Please Wait Document Is Translating",
                },
            });
        } else {
            setTranslateLoading(() => true);
            setLoader(() => true);
            setProgress(0);
            setOriginalLoad(false);
            Api?.translatePdf({ pdf_name: contextStore?.translationFile?.file_identifier, cell_padding: padding })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: res?.data?.response,
                            },
                        });
                        handleTranslationProgress(res?.data?.task_id);
                    } else {
                        setTranslateLoading(false);
                        setOriginalLoad(true);
                    }
                })
                ?.catch((err) => {
                    setTranslateLoading(false);
                    setOriginalLoad(true);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Translate Document",
                        },
                    });
                });
        }
    };

    let timeout: NodeJS.Timeout | null;
    const handleTranslationProgress = (id: string) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        Api?.getTranslateProgress(id)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (Number(res?.data?.progress?.toString()?.replace("%", "")) < 100) {
                        setProgress(Number(res?.data?.progress?.toString()?.replace("%", "")));
                        timeout = setTimeout(() => {
                            handleTranslationProgress(id);
                        }, 1000);
                    } else {
                        setProgress(() => 100);
                        setTimeout(() => {
                            fetchPdf("translated");
                        }, 1000);
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: "Translate Document Completed",
                            },
                        });
                    }
                } else {
                    setTranslateLoading(false);
                }
            })
            ?.catch((err) => {
                setTranslateLoading(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Translate Document",
                    },
                });
            });
    };

    const handleDownload = (file: any) => {
        setDownloadLoading(true);
        if (translateLoading) {
            Api?.downloadTranslateFile({ pdf_name: contextStore?.translationFile?.file_identifier })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        download(res?.data?.document);
                    } else {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "error",
                                message: res?.message || "Failed To Download Document",
                            },
                        });
                    }
                    setDownloadLoading(false);
                })
                ?.catch((err) => {
                    setDownloadLoading(false);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Download Document",
                        },
                    });
                });
        } else {
            download(file);
        }
    };
    const download = (file: any) => {
        const a = document?.createElement("a");
        a.hidden = true;
        a.download = `${contextStore?.translationFile?.file_identifier} ${originalLoad ? "Original.pdf" : "Translated.pdf"}`;
        a.href = file;
        Promise?.resolve()
            ?.then(() => {
                documentRef.current.appendChild(a);
                return true;
            })
            ?.then(() => {
                a.click();
                documentRef.current.removeChild(a);
                setDownloadLoading(false);
            })
            ?.catch((err) => console.log(err));
    };

    // const handleScale = (type: "in" | "out") => {
    //     const width = pageRef?.current?.style?.width;
    //     const height = pageRef?.current?.style?.height;
    //     switch (type) {
    //         case "in":
    //             if (pageRef?.current?.style?.width) {
    //                 pageRef.current.style.width = `${parseInt(width) + 100}px`;
    //                 pageRef.current.style.height = `${parseInt(height) + 100}px`;
    //             }
    //             return;
    //         case "out":
    //             if (parseInt(width) > 200 && parseInt(height) > 300) {
    //                 pageRef.current.style.width = `${parseInt(width) - 100}px`;
    //                 pageRef.current.style.height = `${parseInt(height) - 100}px`;
    //             }
    //             return;

    //         default:
    //             return;
    //     }
    // };

    return (
        <Box ref={documentRef} sx={{ display: "flex", justifyContent: "center", px: 1, pt: 1, width: "100%", height: "100%", background: "#FEFDFD" }}>
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderBottom: "none",
                    borderRadius: "0.7rem 0.7rem 0px 0rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    py: 2,
                    width: "50rem",
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                }}>
                <Typography sx={{ color: "#007BFF", fontSize: "1.2rem", fontWeight: 550, px: 2 }} noWrap>
                    {contextStore?.translationFile?.file_name}
                </Typography>
                <Stack px={2} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography>Translate to</Typography>
                        <Select
                            size="small"
                            value={language}
                            onChange={(e: any) => setLanguage(e?.target?.value)}
                            sx={{
                                height: "1.9rem",
                                fontSize: "0.85rem",
                                minWidth: "6rem",
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#EBEBEB" },
                            }}>
                            <MenuItem value="English">English</MenuItem>
                        </Select>
                    </Stack>
                    <Stack direction={"row"} gap={1}>
                        <IconButton onClick={() => handleDownload(pdf)}>
                            {downloadLoading ? (
                                <CircularProgress sx={{ width: "20px", height: "20px" }} size={"small"} />
                            ) : (
                                <Download sx={{ fontSize: "1.2rem", color: "#007BFF" }} />
                            )}
                        </IconButton>
                        <Button
                            sx={{ textTransform: "none", color: "#007BFF", "&:disabled": { background: "#E9E9E9", color: "#0006" } }}
                            onClick={() => {
                                if (loader) {
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "error",
                                            message: "Please wait For Translation To Complete",
                                        },
                                    });
                                } else {
                                    setTranslateLoading(false);
                                    setOriginalLoad(true);
                                    fetchPdf("original");
                                }
                            }}
                            disabled={originalLoad}
                            variant="outlined">
                            View Original
                        </Button>
                    </Stack>
                </Stack>
                <Divider />
                <Stack sx={{ flex: "1 0 10vh", overflowY: "scroll", py: 1 }} px={2}>
                    {loader ? (
                        <Box
                            sx={{
                                width: "100%",
                                height: "20rem",
                                position: "relative",
                            }}>
                            <RoundedWave progress={progress} />
                        </Box>
                    ) : (
                        pdf && (
                            <Document file={pdf} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                                {Array(numPages)
                                    ?.fill(0)
                                    ?.map((_, indexnumber) => (
                                        <DocPage indexnumber={indexnumber} key={indexnumber} />
                                    ))}
                            </Document>
                        )
                    )}
                </Stack>
                <Divider />
                <Stack px={2} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Switch size="small" checked={padding} onChange={(e: any) => setPadding(e?.target?.checked)} />
                        <Typography sx={{ color: "#007BFF", fontSize: "0.95rem" }}>Cell padding</Typography>
                        <Tooltip arrow title={<Typography variant="caption">Recommended for documents with many tables.</Typography>}>
                            <IconExclamationCircle size={20} />
                        </Tooltip>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <IconButton
                            sx={{ border: "1px solid #d9d9d9" }}
                            onClick={() => {
                                setContext({ ...contextStore, translation: { ...contextStore.translation, type: "in" } });
                            }}>
                            <Add color="primary" />
                        </IconButton>
                        <IconButton
                            sx={{ border: "1px solid #d9d9d9" }}
                            onClick={() => {
                                setContext({ ...contextStore, translation: { ...contextStore.translation, type: "out" } });
                            }}>
                            <Remove color="secondary" />
                        </IconButton>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Button sx={{ textTransform: "none" }} onClick={() => navigate("/translate")}>
                            Go Back
                        </Button>
                        <Button
                            variant="contained"
                            disabled={translateLoading}
                            onClick={translatePdf}
                            sx={{ color: "#fff", background: "#007BFF", textTransform: "none" }}>
                            Translate
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

const DocPage = ({ indexnumber }: { indexnumber: number }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const canvasRef = useRef<any>();
    useEffect(() => {
        const observer = new IntersectionObserver((entry) => {
            entry?.forEach((page) => {
                if (page?.isIntersecting) {
                    setVisible(() => true);
                } else {
                    setVisible(() => false);
                }
            });
        });
        if (canvasRef?.current) observer.observe(canvasRef?.current);
        return () => {
            if (canvasRef?.current) observer.unobserve(canvasRef?.current);
        };
    }, [canvasRef?.current]);

    useEffect(() => {
        if (visible) {
            const width = canvasRef?.current?.style?.width;
            const height = canvasRef?.current?.style?.height;
            if (contextStore.translation?.type === "in") {
                canvasRef.current.style.width = `${parseInt(width) + 100}px`;
                canvasRef.current.style.height = `${parseInt(height) + 100}px`;
            }
            if (contextStore.translation?.type === "out") {
                canvasRef.current.style.width = `${parseInt(width) - 100}px`;
                canvasRef.current.style.height = `${parseInt(height) - 100}px`;
            }
        }
    }, [contextStore?.translation]);

    return (
        <Page
            canvasRef={canvasRef}
            key={indexnumber}
            pageNumber={indexnumber + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onLoadSuccess={() => {
                canvasRef.current.style.width = "595px";
                canvasRef.current.style.height = "842px";
            }}></Page>
    );
};

export default DocumentRepot;
