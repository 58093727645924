import React from "react";

const Language = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_6822_1094" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6822_1094)">
                <path
                    d="M7.93268 14.6663L10.966 6.66634H12.366L15.3993 14.6663H13.9993L13.2993 12.633H10.066L9.33268 14.6663H7.93268ZM10.466 11.4663H12.866L11.6994 8.16634H11.6327L10.466 11.4663ZM2.66602 12.6663L1.73268 11.733L5.09935 8.36634C4.67713 7.89967 4.30768 7.41634 3.99102 6.91634C3.67435 6.41634 3.39935 5.88856 3.16602 5.33301H4.56602C4.76602 5.73301 4.9799 6.09412 5.20768 6.41634C5.43546 6.73856 5.71046 7.07745 6.03268 7.43301C6.52157 6.89967 6.92713 6.35245 7.24935 5.79134C7.57157 5.23023 7.84379 4.63301 8.06602 3.99967H0.666016V2.66634H5.33268V1.33301H6.66602V2.66634H11.3327V3.99967H9.39935C9.16602 4.78856 8.84935 5.55523 8.44935 6.29967C8.04935 7.04412 7.5549 7.74412 6.96602 8.39967L8.56602 10.033L8.06602 11.3997L5.99935 9.33301L2.66602 12.6663Z"
                    fill="#747474"
                />
            </g>
        </svg>
    );
};

export default Language;
