const GLOBAL_RELEASE_VERSION: string = "0.0.1";
const TEST_RELEASE_VERSION: string = "0.0.1";

type Single_Environment = {
    env: string;
    USER_URL: string;
    QUERY_URL: string;
    LOGS: boolean;
    RELEASE: string;
    API_URL: string;
    socketUrl: string;
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
    AUTH0_REDIRECT_URI: string;
    AUTH0_AUDIENCE: string;
    AUTH0_TOKEN: string;
    AUTH0_CONNECTION: string;
    claims: string;
    claimsSocketUrl: string;
    STRUCTURED_URL: string;
    STRUCTURED_SOCKET: string;
    SOFIE_SOCKET: string;
};

type Environment_ = {
    [key: string]: Single_Environment;
};

const _Environments: Environment_ = {
    production: {
        env: "production",
        USER_URL: "https://production-llm-api.prr.ai/bond_llm",
        QUERY_URL: "https://production-llm-api.prr.ai/bond_llm",
        API_URL: "https://development-api.bond-chat.prr.ai",
        socketUrl: "wss://production-llm-api.prr.ai/bond_llm",
        AUTH0_DOMAIN: "claims-development.us.auth0.com",
        AUTH0_CLIENT_ID: "qGjsZYowSJLv7cakKXWIzTziquXTmH8d",
        AUTH0_REDIRECT_URI: "redirect",
        AUTH0_AUDIENCE: "https://claims-development.us.auth0.com/api/v2/",
        AUTH0_CONNECTION: "Username-Password-Authentication",
        AUTH0_TOKEN:
            "@@auth0spajs@@::qGjsZYowSJLv7cakKXWIzTziquXTmH8d::https://claims-development.us.auth0.com/api/v2/::openid profile email offline_access",
        LOGS: false,
        RELEASE: GLOBAL_RELEASE_VERSION,
        claimsSocketUrl: "wss://development-claims-ssx.softsensor.ai",
        claims: "https://development-claims-ssx.softsensor.ai",
        STRUCTURED_URL: "https://struct-api-ssx.softsensor.ai/structured_data",
        STRUCTURED_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
        SOFIE_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
    },
    staging: {
        env: "staging",
        USER_URL: "https://softxapi-stag.softsensor.ai/bond_llm",
        QUERY_URL: "https://softxqs-demo.softsensor.ai/query",
        API_URL: "https://staging-api.bond-chat.prr.ai",
        socketUrl: "wss://softxapi-stag.softsensor.ai/bond_llm",
        AUTH0_DOMAIN: "claims-development.us.auth0.com",
        AUTH0_CLIENT_ID: "qGjsZYowSJLv7cakKXWIzTziquXTmH8d",
        AUTH0_REDIRECT_URI: "redirect",
        AUTH0_AUDIENCE: "https://claims-development.us.auth0.com/api/v2/",
        AUTH0_CONNECTION: "Username-Password-Authentication",
        AUTH0_TOKEN:
            "@@auth0spajs@@::qGjsZYowSJLv7cakKXWIzTziquXTmH8d::https://claims-development.us.auth0.com/api/v2/::openid profile email offline_access",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
        claimsSocketUrl: "wss://development-claims-ssx.softsensor.ai",
        claims: "https://development-claims-ssx.softsensor.ai",
        STRUCTURED_URL: "https://struct-api-ssx.softsensor.ai/structured_data",
        STRUCTURED_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
        SOFIE_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
    },
    development: {
        env: "development",
        USER_URL: "https://softxapi-demo.softsensor.ai/bond_llm",
        QUERY_URL: "https://softxqs-demo.softsensor.ai/query",
        API_URL: "https://development-api.bond-chat.prr.ai",
        socketUrl: "wss://softxapi-demo.softsensor.ai/bond_llm",
        // socketUrl: "wss://staging-llm-api.prr.ai/bond_llm",
        AUTH0_DOMAIN: "claims-development.us.auth0.com",
        AUTH0_CLIENT_ID: "qGjsZYowSJLv7cakKXWIzTziquXTmH8d",
        AUTH0_REDIRECT_URI: "redirect",
        AUTH0_AUDIENCE: "https://claims-development.us.auth0.com/api/v2/",
        AUTH0_CONNECTION: "Username-Password-Authentication",
        AUTH0_TOKEN:
            "@@auth0spajs@@::qGjsZYowSJLv7cakKXWIzTziquXTmH8d::https://claims-development.us.auth0.com/api/v2/::openid profile email offline_access",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
        claimsSocketUrl: "wss://development-claims-ssx.softsensor.ai",
        claims: "https://development-claims-ssx.softsensor.ai",
        STRUCTURED_URL: "https://struct-api-ssx.softsensor.ai/structured_data",
        STRUCTURED_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
        SOFIE_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
    },
    local: {
        env: "local",
        USER_URL: "https:/softxapi-demo.softsensor.ai/bond_llm",
        QUERY_URL: "https://softxqs-demo.softsensor.ai/query",
        API_URL: "https://development-api.bond-chat.prr.ai",
        socketUrl: "wss://softxapi-demo.softsensor.ai/bond_llm",

        AUTH0_DOMAIN: "claims-development.us.auth0.com",
        AUTH0_CLIENT_ID: "qGjsZYowSJLv7cakKXWIzTziquXTmH8d",
        AUTH0_REDIRECT_URI: "redirect",
        AUTH0_AUDIENCE: "https://claims-development.us.auth0.com/api/v2/",
        AUTH0_CONNECTION: "Username-Password-Authentication",
        AUTH0_TOKEN:
            "@@auth0spajs@@::qGjsZYowSJLv7cakKXWIzTziquXTmH8d::https://claims-development.us.auth0.com/api/v2/::openid profile email offline_access",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
        claimsSocketUrl: "wss://development-claims-ssx.softsensor.ai",
        claims: "https://development-claims-ssx.softsensor.ai",
        STRUCTURED_URL: "https://struct-api-ssx.softsensor.ai/structured_data",
        STRUCTURED_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
        SOFIE_SOCKET: "wss://struct-api-ssx.softsensor.ai/structured_data",
    },
};

function getEnvironment() {
    let env;
    if (window.location.href.includes("localhost")) {
        env = "local";
    } else if (window.location.href.includes("development")) {
        env = "development";
    } else if (window.location.href.includes("staging")) {
        env = "staging";
    } else {
        env = "production";
    }
    return _Environments[env];
}

const Environment = getEnvironment();

export default Environment;
