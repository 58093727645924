import { Close, Menu } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatabaseIcon from "../../Icons/DatabaseIcon";
import ClaimsLoo from "../../Icons/ClaimsLoo";
import Language from "../../Icons/Language";

const Index = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));
    const [selected, setSelected] = useState("Home");
    const [menuCollapse, setMenuCollapse] = useState<boolean>(true);

    const options = [
        { title: "Home", onClick: () => navigate("/"), icon: ClaimsLoo, key: "Home" },
        { title: "Translate", onClick: () => navigate("/translate"), icon: Language, key: "Translate" },
    ];

    const styles = (obj?: boolean) => {
        if (smd) {
            return {
                main: {
                    position: "absolute",
                    transition: "100ms ease-out",
                    ...(menuCollapse
                        ? { width: "1rem", boxShadow: "", alignItems: "center" }
                        : {
                              height: "-webkit-fill-available",
                              width: "16.5rem",
                              background: "#fff",
                              alignItems: "start",
                          }),
                },
                inner1: {
                    pt: 5,
                    ...(menuCollapse ? { visibility: "hidden" } : { width: "-webkit-fill-available", visibility: "visible" }),
                },
                inner2: {
                    ...(menuCollapse ? { visibility: "hidden" } : { width: "-webkit-fill-available", visibility: "visible" }),
                },
                list: {
                    justifyContent: "flex-start",
                    pl: 1,
                    boxShadow: obj ? "0px 0px 6px 1px #f2f2f2" : "",
                },
            };
        }
    };

    return (
        <Box
            sx={{
                minWidth: "3.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 3,
                py: "1rem",
                zIndex: 2,
                boxShadow: "0.85333px 8px 16.21333px 0px rgba(83, 124, 204, 0.12)",
                ...styles()?.main,
            }}>
            {smd && (
                <IconButton
                    onClick={() => setMenuCollapse(!menuCollapse)}
                    sx={{
                        ...(menuCollapse ? {} : { position: "absolute", right: "4px" }),
                    }}>
                    {menuCollapse ? <Menu /> : <Close />}
                </IconButton>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: menuCollapse ? "center" : "start",
                    gap: 1,
                    px: 1.5,
                    flex: "1 0 10vh",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {},
                    "&::-webkit-scrollbar-thumb": {
                        boxShadow: "0px 0px 3px 1px #84ceffaa",
                    },
                    ...styles()?.inner1,
                }}>
                {options?.map((elem: any) => (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            cursor: "pointer",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                            ...styles(selected === elem?.title)?.list,
                        }}
                        onClick={() => {
                            setSelected(elem?.title);
                            elem?.onClick();
                            setMenuCollapse(true);
                        }}>
                        <Tooltip title={elem?.key} arrow placement="right">
                            <span>
                                <IconButton
                                    key={elem?.title}
                                    sx={{
                                        height: "2.4rem",
                                        width: "2.4rem",
                                        background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                        boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                        "& *": {
                                            fill: selected === elem?.title ? "#007BFF" : "inherit",
                                        },
                                        py: 1,
                                        borderRadius: "13px",
                                    }}>
                                    <elem.icon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {!menuCollapse && <Typography fontWeight={600}>{elem?.title}</Typography>}
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: menuCollapse ? "center" : "start",
                    gap: 1,
                    px: 1.5,
                    ...styles()?.inner2,
                }}>
                {[
                    {
                        title: "Data Sources",
                        onClick: () => {
                            // navigate("/data_source/main");
                        },
                        icon: DatabaseIcon,
                        key: "Sources",
                    },
                ]?.map((elem) => (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "-webkit-fill-available",
                            cursor: "pointer",
                            justifyContent: "center",
                            ...styles(selected === elem?.title)?.list,
                        }}
                        onClick={() => {
                            setSelected(elem?.title);
                            elem?.onClick();
                            setMenuCollapse(true);
                        }}>
                        <Tooltip title={elem?.key} arrow placement="right">
                            <span>
                                <IconButton
                                    key={elem?.title}
                                    sx={{
                                        height: "2.4rem",
                                        width: "2.4rem",
                                        background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                        boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                                        "& *": {
                                            fill: selected === elem?.title ? "#007BFF" : "inherit",
                                        },
                                        my: 0.5,
                                        borderRadius: "13px",
                                    }}>
                                    <elem.icon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        {!menuCollapse && <Typography fontWeight={600}>{elem?.title}</Typography>}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Index;
