import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import MesageBox from "./MesageBox";
import InputField from "./InputBox";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ClaimSocket from "../utils/ClaimSocket";
import { Context } from "../../../store/store";

const ChatBox = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const theme = useTheme();
    const scrollRef = useRef<any>();
    const [msg, socketHooks, msgRef] = ClaimSocket();
    const [msgList, setMsgList] = useState<any>([
        {
            msg: (
                <Box>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography
                            sx={{
                                color: "#292D32",
                                fontSize: "1rem",
                            }}
                        >
                            Welcome to SoftsensorX! Your go-to for seamless assistance
                        </Typography>
                        <Box>
                            <Typography sx={{ fontSize: "0.8rem", color: "#6B7582", mt: 0.7 }}>Try Asking</Typography>
                            {[
                                "What are the main topics and key points covered in the document discussing financial analysis concepts?",
                                "Can you provide an overview of the document's content, highlighting the main topics and key points related to financial analysis concepts?",
                                "What specific financial analysis concepts are explored in the document and what are the main takeaways from these discussions?",
                            ]?.map((item) => (
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        px: 2,
                                        py: 0.4,
                                        background: "rgba(217, 217, 217, 0.3)",
                                        borderRadius: "10px",
                                        mt: 1,
                                    }}
                                >
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Box>
            ),
            in: true,
            time: new Date(),
            latest: false,
            generated: "auto",
            initial: true,
        },
    ]);

    useEffect(() => {
        if (contextStore?.claims?.chat?.top_match_docs) {
            setTimeout(() => {
                const pdf_ids = [
                    ...contextStore?.claims?.chat?.top_match_docs?.map((item: any) => item?.pdf_id),
                    contextStore?.claims?.chat?.pdf?.pdf_id,
                ];
                socketHooks?.sendJsonMessage({
                    pdf_uuids: {
                        pdf_ids,
                        model: contextStore?.temparary?.model,
                    },
                });
            }, 500);
        } else {
            navigate("/claims");
        }
    }, []);

    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    const handleSend = async (value: string) => {
        msgRef.load = true;

        const setting = {
            model: contextStore?.temparary?.model || "gpt-35-turbo-16k",
            temperature: contextStore?.temparary?.temperature / 100 || 0,
            top_k: contextStore?.temparary?.top_k || 1,
            language: contextStore?.temparary?.language || "English",
            memory: contextStore?.temparary?.memory || "0",
            prompt: contextStore?.prompt || "Answer in detail",
            persona:
                contextStore.activeSource === "pathalogy"
                    ? "Pathalogist"
                    : contextStore?.temparary?.persona
                      ? contextStore?.temparary?.persona
                      : "Financial Analyst",
        };

        setMsgList([
            ...msgList?.map((item: any) => {
                item.latest = false;
                return item;
            }),
            { out: true, msg: value, time: new Date(), name: "You" },
            {
                in: true,
                msg: "",
                time: new Date(),
                latest: true,
                generated: "self",
            },
        ]);

        socketHooks?.sendJsonMessage({
            query: value,
        });
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        timeout = setTimeout(() => {
            if (!msgRef?.load) {
                setMsgList((prev: any[]) => {
                    return prev?.map((item: any) => {
                        if (item?.latest) {
                            if (typeof msgRef?.json === "object" && msgRef?.json?.response) {
                                item.msg = msgRef?.json;
                                item.latest = false;
                                msgRef.json = {};
                            }
                            return item;
                        } else {
                            return item;
                        }
                    });
                });
            }
        }, 10);

        return () => clearTimeout(timeout);
    }, [msgRef?.load]);

    const styles = (obj?: any) => {
        if (smd) {
            return {
                inputBox: {
                    width: "85%",
                },
                chatBox: {
                    width: "90%",
                },
                messageBox: {
                    justifyContent: obj?.in ? "flex-end" : "flex:start",
                },
            };
        }
        return {};
    };

    return (
        <Box sx={{ flex: 1, display: "flex", position: "relative" }}>
            <Box sx={{ position: "absolute", top: "1rem", left: "1rem" }}>
                <Button
                    sx={{
                        color: "#007BFF",
                        fontSize: "0.9rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                    }}
                    onClick={() => navigate(contextStore?.claims?.state?.prevRoute || "/claims")}
                >
                    <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
                    Back
                </Button>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "93.4vh",
                        transition: "300ms ease-out",
                        width: "100%",
                    }}
                >
                    <Box
                        ref={scrollRef}
                        sx={{
                            flex: 1,
                            overflowY: "auto",
                            my: 1.5,
                            width: "75%",
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            pb: 1,
                            ...styles()?.chatBox,
                        }}
                    >
                        {msgList?.map((elem: any, index: number) => (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    ...styles(elem)?.messageBox,
                                }}
                            >
                                <MesageBox
                                    elem={elem}
                                    msgRef={msgRef}
                                    setMsgList={setMsgList}
                                    lastElement={msgList?.length - 1 === index}
                                    scrollRef={scrollRef}
                                />
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            pb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: "65%",
                                display: "flex",
                                alignItems: "center",
                                gap: 1.5,
                                position: "relative",
                                mt: 1,
                                ...styles()?.inputBox,
                            }}
                        >
                            <InputField handleSend={handleSend} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ChatBox;
