import { ArrowBackIos, Close } from "@mui/icons-material";
import { Box, Typography, Button, IconButton, Divider, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import { Context } from "../../../store/store";
import Bounce from "../../Loader/Bounce";

const Summary = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [pdfName, setPdfName] = useState<string>("");
    const [summary, setSummary] = useState<string>("");
    const [problemStatement, setProblemStatement] = useState<string>("");
    const [filteredFiles, setFilteredFiles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        if (contextStore?.claims?.summary) {
            setPdfName(contextStore?.claims?.summary?.pdf_name || contextStore?.claims?.summary?.file_name);
            handleSummary(contextStore?.claims?.summary?.pdf_id || contextStore?.claims?.summary?.file_id);
        }
    }, [contextStore?.claims?.summary]);

    useEffect(() => {
        if (contextStore?.claims?.state?.filteredFiles) {
            setFilteredFiles(contextStore?.claims?.state?.filteredFiles);
        }
    }, [contextStore?.claims?.state?.filteredFiles]);

    const handleSummary = (pdf_id: string) => {
        setLoader(true);
        console.log(contextStore?.claims?.summary);
        Api?.getClaimsExistingProjectsPdfSummary({ pdf_id })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setSummary(res?.data?.Summary);
                    setProblemStatement(res?.data["Problem Statement"]);
                } else {
                    setSummary("");
                    setProblemStatement("");
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setSummary("");
                setProblemStatement("");
            });
    };

    return (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", py: 3, gap: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: "linear-gradient(to right, #fff, #E3F4FF)",
                        width: "75%",
                        borderRadius: "19px",
                        px: 2,
                        py: 0.5,
                        border: "1px solid #E2E2E2",
                    }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <IconButton onClick={() => navigate(contextStore?.claims?.state?.prevRoute || "/claims")}>
                            <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
                        </IconButton>
                        <Typography sx={{ color: "#007BFF", fontSize: "0.8rem" }}>Back</Typography>
                    </Box>
                    <Typography sx={{ color: "#007BFF", fontSize: "0.9rem" }}>{pdfName}</Typography>
                </Box>
                <Box
                    sx={{
                        width: "75%",
                        borderRadius: "13px",
                        px: 2,
                        border: "1px solid #E2E2E2",
                        height: "73vh",
                        overflowY: "scroll",
                        position: "relative",
                    }}>
                    <Typography
                        sx={{
                            color: "#007BFF",
                            py: 1.5,
                            position: "sticky",
                            top: "0px",
                            background: "#fff",
                        }}>
                        Summary
                    </Typography>
                    {loader ? (
                        <Box sx={{ height: "15rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Bounce />
                        </Box>
                    ) : (
                        <Typography sx={{ fontSize: "0.96rem", fontFamily: "Outfit" }}>
                            {summary?.split("\n")?.map((msg) => (
                                <>
                                    {msg} <br />
                                </>
                            ))}
                        </Typography>
                    )}
                    <Typography
                        sx={{
                            color: "#007BFF",
                            py: 1.5,
                            position: "sticky",
                            top: "0px",
                            background: "#fff",
                        }}>
                        Problem Statement
                    </Typography>
                    {loader ? (
                        <Box sx={{ height: "15rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Bounce />
                        </Box>
                    ) : (
                        <Typography sx={{ fontSize: "0.96rem", fontFamily: "Outfit" }}>
                            {problemStatement?.split("\n")?.map((msg) => (
                                <>
                                    {msg} <br />
                                </>
                            ))}
                        </Typography>
                    )}
                </Box>
            </Box>
            {filteredFiles?.length > 0 && (
                <>
                    <Box
                        sx={{
                            width: "16px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderWidth: "0px 2px 0px 2px",
                            borderStyle: "solid",
                            borderColor: "#E3F4FF",
                        }}>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}></Box>
                    </Box>
                    <Box sx={{ width: "20rem", display: "felx", flexDirection: "column", gap: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 1 }}>
                            <Typography>Claim Docs ({filteredFiles?.length})</Typography>
                        </Box>
                        <Divider sx={{ borderColor: "#F3F3F4" }} />
                        <Box sx={{ p: 1 }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
                                <Typography sx={{ color: "#6B7582", fontSize: "0.9rem" }}>{filteredFiles?.length} Documents</Typography>
                            </Box>
                            {filteredFiles?.map((pdf) => (
                                <Box
                                    sx={{
                                        p: 1,
                                        pb: 1.5,
                                        cursor: "pointer",
                                        transition: "200ms ease-out",
                                        borderRadius: "6px",
                                        "&:hover": {
                                            boxShadow: "0px 2px 4px 1px #00000010",
                                            px: 2,
                                        },
                                        my: 0.4,
                                    }}
                                    onClick={() => {
                                        setPdfName(pdf?.pdf_name || pdf?.file_name);
                                        handleSummary(pdf?.pdf_id || pdf?.file_id);
                                    }}>
                                    <Tooltip title={pdf?.pdf_name || pdf?.file_name || pdf?.name}>
                                        <Typography
                                            noWrap
                                            sx={{
                                                fontSize: "0.86rem",
                                                color:
                                                    pdfName === pdf?.pdf_name || pdfName === pdf?.file_name || pdfName === pdf?.name
                                                        ? "#007BFF"
                                                        : "inherit",
                                            }}>
                                            {pdf?.pdf_name || pdf?.file_name || pdf?.name}
                                        </Typography>
                                    </Tooltip>
                                    <Divider sx={{ borderColor: "#F3F3F4", pt: 0.1 }} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Summary;
