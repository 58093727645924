import React from "react";

const Expand = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.332 18.333H3.66536V20.1663H18.332V18.333ZM18.332 1.83301H3.66536V3.66634H18.332V1.83301ZM10.082 8.24967H7.33203L10.9987 4.58301L14.6654 8.24967H11.9154V13.7497H14.6654L10.9987 17.4163L7.33203 13.7497H10.082V8.24967Z"
                fill="black"
            />
        </svg>
    );
};

export default Expand;
