import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import { Context } from "../../../store/store";
import ProfileAvatar from "../../../Icons/ProfileAvatar";
import Bounce from "../../Loader/Bounce";
import ExcelJS from "exceljs";
import SaveAs from "file-saver";

const Comparison = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [pdfName, setPdfName] = useState<string>("");
    const [compareDoc, setCompareDoc] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        if (contextStore?.claims?.state?.compareDoc || contextStore?.claims?.state?.pdfName) {
            if (contextStore?.claims?.state?.compareDoc) setCompareDoc(contextStore?.claims?.state?.compareDoc);
            if (contextStore?.claims?.state?.pdfName) setPdfName(contextStore?.claims?.state?.pdfName);
        } else if (contextStore?.claims?.comparison) {
            setLoader(true);
            setPdfName(contextStore?.claims?.comparison?.pdf?.pdf_name);
            Api?.getClaimsExistingProjectsPdfCompare({
                claim_docs: [contextStore?.claims?.comparison?.pdf?.pdf_id],
                check_docs: contextStore?.claims?.comparison?.top_match_docs?.map((item: any) => item?.pdf_id),
                model: contextStore?.model,
            })
                ?.then((res: any) => {
                    if (res?.status === 200 && Array?.isArray(res?.data)) {
                        setCompareDoc(res?.data);
                    }
                    setLoader(false);
                })
                ?.catch((err) => setLoader(false));
        }
    }, [contextStore?.claims?.comparison, contextStore?.claims?.state]);

    const handleDownload = () => {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = "Claims-AI compare";
        workbook.created = new Date();
        let worksheet = workbook.addWorksheet(`Compare Report`);
        const headers = ["Relationships", "Details", "Verification", "Missed Details", "Document 1", "Document 2"];
        worksheet.columns = headers?.map((key) => ({ header: key, key, width: 10 }));
        let lastHeadRow = 1;
        ["A", "B", "C", "D", "E", "F"]?.forEach((row: string) => {
            worksheet.getCell(`${row}${lastHeadRow}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "007BFF" },
            };
            worksheet.getCell(`${row}${lastHeadRow}`).font = {
                color: { argb: "FFFFFFFF" },
                bold: true,
            };
            worksheet.getCell(`${row}${lastHeadRow}`).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            worksheet.getColumn(`${row}`).width = 35;
        });
        ["A", "B", "C", "D", "E", "F"]?.forEach((row: string) => {
            worksheet.getCell(`${row}${lastHeadRow}`).border = {
                top: { style: "thin", color: { argb: "8F8F8F8F" } },
                left: { style: "thin", color: { argb: "8F8F8F8F" } },
                bottom: { style: "thin", color: { argb: "8F8F8F8F" } },
                right: { style: "thin", color: { argb: "8F8F8F8F" } },
            };
        });
        compareDoc?.forEach((item: any) => {
            worksheet.addRow([
                `${item?.Relationships || ""}`,
                `${item?.Details || ""}`,
                `${item?.Verified?.toString() || ""}`,
                `${item?.Missed_Details || ""}`,
                `${item?.Document_1?.Name || ""}`,
                `${item?.Document_2?.Name || ""}`,
            ]);
            lastHeadRow += 1;
        });

        worksheet.eachRow((row) =>
            row.eachCell(
                (cell) =>
                    (cell.alignment = {
                        horizontal: "center",
                    })
            )
        );

        worksheet.views = [{ state: "frozen", xSplit: 0, ySplit: 0, activeCell: "A1", showGridLines: true }];
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            SaveAs(blob, `SoftsensorX Claims Compare Report`);
        });
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 3, width: "100%", p: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "linear-gradient(to right, #fff, #E3F4FF)",
                    width: "80%",
                    borderRadius: "19px",
                    px: 2,
                    py: 0.5,
                    border: "1px solid #E2E2E2",
                }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <IconButton onClick={() => navigate("/claims/reports")}>
                        <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                    <Typography sx={{ color: "#007BFF", fontSize: "0.8rem" }}>Back</Typography>
                </Box>
                <Typography sx={{ color: "#007BFF", fontSize: "0.9rem" }}>{pdfName}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "80%" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <ProfileAvatar />
                    <Typography sx={{ fontSize: "0.85rem" }}>Claims Comparison </Typography>
                </Box>
                <Stack gap={1} direction={"row"}>
                    <Button
                        disabled={loader}
                        variant="outlined"
                        sx={{ color: "#007BFF", borderColor: "#007BFF", px: 3, borderRadius: "19px" }}
                        size="small"
                        onClick={() => {
                            try {
                                setContext({
                                    ...contextStore,
                                    claims: {
                                        ...contextStore?.claims,
                                        chat: {
                                            ...contextStore?.claims?.comparison,
                                        },
                                        state: {
                                            prevRoute: "/claims/comparison",
                                            compareDoc,
                                            pdfName,
                                        },
                                    },
                                });
                                navigate("/claims/chat");
                            } catch (error) {
                                console.log(error);
                            }
                        }}>
                        Chat
                    </Button>
                    <Button
                        disabled={loader}
                        variant="outlined"
                        sx={{ color: "#007BFF", borderColor: "#007BFF", px: 3, borderRadius: "19px" }}
                        size="small"
                        onClick={handleDownload}>
                        Download
                    </Button>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    height: "70vh",
                    overflowY: "scroll",
                    mt: 0.3,
                }}>
                {loader ? (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "20rem",
                        }}>
                        <Bounce />
                    </Box>
                ) : (
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        textAlign: "center",
                                        background: "#fafafa",
                                        border: "1px solid #D9D9D9",
                                        borderTopLeftRadius: "6px",
                                    }}>
                                    Relationships
                                </TableCell>
                                <TableCell sx={{ textAlign: "center", background: "#fafafa", border: "1px solid #D9D9D9" }}>Details</TableCell>
                                <TableCell sx={{ textAlign: "center", background: "#fafafa", border: "1px solid #D9D9D9" }}>Verification</TableCell>
                                <TableCell sx={{ textAlign: "center", background: "#fafafa", border: "1px solid #D9D9D9" }}>Missed Details</TableCell>
                                <TableCell sx={{ textAlign: "center", background: "#fafafa", border: "1px solid #D9D9D9" }}>Document 1</TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: "center",
                                        background: "#fafafa",
                                        border: "1px solid #D9D9D9",
                                        borderTopRightRadius: "6px",
                                    }}>
                                    Document 2
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {compareDoc?.map((item: any) => (
                                <TableRow>
                                    <TableCell sx={{ border: "1px solid #D9D9D9", borderBottomLeftRadius: "6px" }}>{item?.Relationships}</TableCell>
                                    <TableCell sx={{ border: "1px solid #D9D9D9" }}>{item?.Details}</TableCell>
                                    <TableCell sx={{ border: "1px solid #D9D9D9" }}>{item?.Verified?.toString()}</TableCell>
                                    <TableCell sx={{ border: "1px solid #D9D9D9" }}>{item?.Missed_Details}</TableCell>
                                    <TableCell sx={{ border: "1px solid #D9D9D9" }}>{item?.Document_1?.Name}</TableCell>
                                    <TableCell sx={{ border: "1px solid #D9D9D9", borderBottomRightRadius: "6px" }}>
                                        {item?.Document_2?.Name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </Box>
    );
};

export default Comparison;
