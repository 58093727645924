import { Box, Button, Checkbox, CircularProgress, Divider, IconButton, Input, Menu, MenuItem, Stack, Switch, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import SortSection from "./SortSection";
import LibraryProject from "./LibraryProject";
import UploadingFiles from "./UploadingFiles";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { Api } from "../../../apis";
import { Context } from "../../../store/store";
import TitleEditIcon from "../../../Icons/TitleEditIcon";
import AddIcon from "../../../Icons/AddIcon";
import SummaryIcon from "../../../Icons/SummaryIcon";
import UploadBoxIcon from "../../../Icons/UploadBoxIcon";

const CreateProject = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const inputRef = useRef<any>();
    const [project, setProject] = useState<any>({ title: "New Project", created_at: Date() });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [listType, setListType] = useState<string>("Upload");
    const [filteredFiles, setFilteredFiles] = useState<any[]>([]);
    const [uploadFiles, setUploadFiles] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (contextStore?.claims?.state?.listType) {
            setListType(contextStore?.claims?.state?.listType);
            setContext({
                ...contextStore,
                claims: {
                    ...contextStore?.claims,
                    state: {},
                },
            });
        }
    }, [contextStore?.claims?.state?.listType]);

    useEffect(() => {
        if (contextStore?.claims?.state?.filteredFiles) {
            setFilteredFiles(contextStore?.claims?.state?.filteredFiles);
            setContext({
                ...contextStore,
                claims: {
                    ...contextStore?.claims,
                    state: {},
                },
            });
        }
    }, [contextStore?.claims?.state?.filteredFiles]);

    const handleUploadLibrary = () => {
        setLoading(true);
        const formData = new FormData();
        formData?.append("project_name", project?.title);
        formData?.append("user_id", contextStore?.profile?.userId);
        filteredFiles?.forEach((item) => formData?.append("pdf_ids", item?.file_id));
        Api?.uploadClaimCreateLibraryProject(formData)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    Api?.getClaimsExistingProjects({ user_id: contextStore?.profile?.userId })?.then((claim: any) => {
                        if (claim?.status === 200) {
                            navigate("/claims");
                            setContext({
                                ...contextStore,
                                claims: { ...contextStore?.claims, projects: claim?.data?.projects },
                            });
                        }
                    });
                }
                setLoading(false);
            })
            ?.catch((err) => setLoading(false));
    };

    return listType === "Upload" ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                width: "100%",
                background: "#FEFDFD",
                position: "relative",
            }}>
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    width: "50rem",
                    height: "fit-content",
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                }}>
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                color: "#007BFF",
                                fontSize: "1.07rem",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                fontFamily: "Gilroy-SemiBold !important",
                            }}>
                            <Input ref={inputRef} value={project?.title} onChange={(e: any) => setProject({ ...project, title: e?.target?.value })} />
                            <IconButton onClick={() => inputRef?.current?.click()}>
                                <TitleEditIcon />
                            </IconButton>
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Box>
                                <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
                                    <AddIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    PaperProps={{
                                        style: {
                                            padding: "0px 5px",
                                            width: "9rem",
                                        },
                                    }}>
                                    {["Upload", "Library"]?.map((item: string) => (
                                        <MenuItem
                                            sx={{
                                                background: listType === item ? "#E3F4FF" : "inherit",
                                                borderRadius: "7px",
                                            }}
                                            onClick={() => {
                                                setListType(item);
                                                setAnchorEl(null);
                                            }}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            {filteredFiles?.length > 0 && <SortSection setPdfList={setFilteredFiles} />}
                        </Box>
                    </Box>
                    <Typography sx={{ color: "#9D9B9B", fontSize: "0.9rem" }}>{filteredFiles?.length || uploadFiles?.length} Files</Typography>
                </Box>
                {filteredFiles?.length > 0 ? (
                    <Box sx={{ display: "flex", flexDirection: "column", height: "68vh" }}>
                        <Box sx={{ flex: 1, overflowY: "scroll", px: 3 }}>
                            {filteredFiles?.map((item: any) => (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            p: 1,
                                            borderRadius: "4px",
                                        }}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Typography>{item?.file_name}</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <Typography sx={{ fontSize: "0.8rem", color: "#9D9B9B" }}>
                                                Date {moment(item?.created_at)?.format("DD/MM/YYYY")}
                                            </Typography>
                                            <IconButton
                                                onClick={() => {
                                                    try {
                                                        setContext({
                                                            ...contextStore,
                                                            claims: {
                                                                ...contextStore?.claims,
                                                                summary: {
                                                                    ...item,
                                                                },
                                                                state: {
                                                                    prevRoute: "/claims/new_project",
                                                                    listType: "Upload",
                                                                    filteredFiles,
                                                                },
                                                            },
                                                        });
                                                        navigate("/claims/summary");
                                                    } catch (error) {
                                                        console.log(error);
                                                    }
                                                }}>
                                                <SummaryIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    setFilteredFiles((prev: any[]) => prev?.filter((pdf) => pdf?.file_name !== item?.file_name))
                                                }>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ widt: "100%" }} />
                                </>
                            ))}
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                                pt: 1.5,
                            }}>
                            <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
                                {/* <Switch size="small" />
                                <Box>
                                    <Typography
                                        sx={{ color: "#007BFF", fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem" }}
                                    >
                                        Highlight Claim Documents
                                    </Typography>
                                    <Typography sx={{ fontSize: "0.7rem" }}>Auto- selected</Typography>
                                </Box> */}
                            </Stack>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 0.7 }}>
                                <Stack direction={"row"}>
                                    <Button
                                        sx={{ fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem", color: "#3E3E3E" }}
                                        onClick={() => {
                                            navigate("/claims");
                                        }}
                                        disabled={loading}>
                                        Go back
                                    </Button>
                                </Stack>
                                <Stack direction={"row"}>
                                    <Button
                                        sx={{
                                            fontFamily: "Gilroy-SemiBold",
                                            fontSize: "0.85rem",
                                            color: "#fff",
                                            background: "#007BFF",
                                            "&:hover": {
                                                color: "#fff",
                                                background: "#007BFF",
                                            },
                                            "&:disabled": {
                                                color: "#c2c2c2",
                                                background: "#efefef",
                                            },
                                            px: 3,
                                        }}
                                        onClick={() => handleUploadLibrary()}
                                        disabled={loading}>
                                        {loading ? <CircularProgress style={{ width: "18px", height: "18px" }} /> : "Save"}
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ px: 3 }}>
                        {uploadFiles?.length > 0 ? (
                            <UploadingFiles uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} title={project?.title} />
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        border: "1px dashed #007BFF",
                                        borderRadius: "7px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 3,
                                        userSelect: "none",
                                        cursor: "pointer",
                                        height: "61vh",
                                        transition: "300ms ease-out",
                                    }}
                                    component={"label"}>
                                    <input
                                        hidden
                                        multiple
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e: any) => {
                                            const files = Array?.from(e?.target?.files);
                                            let result = [];
                                            if (uploadFiles?.length > 0) {
                                                result = [...uploadFiles, ...files];
                                            } else {
                                                result = files;
                                            }
                                            setUploadFiles(result);
                                        }}
                                    />
                                    <UploadBoxIcon width={30} />
                                    <Box>
                                        <Typography sx={{ fontStyle: "Gilroy-Medium" }}>
                                            Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                        </Typography>
                                        <Typography sx={{ fontStyle: "Italic", color: "#424F65", fontSize: "0.8rem" }}>Supported .pdf</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        p: 1,
                                        pt: 1.5,
                                    }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 0.7 }}>
                                        <Stack direction={"row"}>
                                            <Button
                                                sx={{
                                                    fontFamily: "Gilroy-SemiBold",
                                                    fontSize: "0.85rem",
                                                    color: "#3E3E3E",
                                                }}
                                                onClick={() => {
                                                    navigate("/claims");
                                                }}
                                                disabled={loading}>
                                                Go back
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    ) : (
        <LibraryProject
            setListType={setListType}
            setFilteredFiles={setFilteredFiles}
            handleClose={() => setListType("Upload")}
            filteredFiles={filteredFiles}
        />
    );
};

export default CreateProject;
