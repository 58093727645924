import React, { useContext, useState } from "react";
import { Box, IconButton, OutlinedInput } from "@mui/material";
import { Context } from "../../../store/store";
import SendWhiteIcon from "../../../Icons/SendWhiteIcon";

const InputField = ({ handleSend }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length === 0) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "warning",
                                message: "Please Ask A Question",
                            },
                        });
                    } else {
                        setQuery(() => "");
                        handleSend(e?.target?.value);
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            setQuery("");
                            handleSend(query);
                        }}>
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputField;
