import { Box, Button, Divider, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { Api } from "../../../apis";
import ProgressSocket from "../utils/ProgressSocket";
import UploadProgess from "./UploadProgess";
import { Context } from "../../../store/store";

const UploadingFiles = ({ uploadFiles, setUploadFiles, title }: { uploadFiles: any[]; setUploadFiles: (files: any[]) => void; title: string }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loading, setLoading] = useState<string>("init");
    const [msg, socketHooks, msgRef] = ProgressSocket();
    const [response, setResponse] = useState<any>({});

    const handleUploadFreshFiles = () => {
        setLoading("upload");
        const formData = new FormData();
        formData?.append("project_name", title);
        formData?.append("user_id", contextStore?.profile?.userId);
        uploadFiles?.forEach((item) => formData?.append("files", item));
        Api?.uploadClaimCreateLibraryProject(formData)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (Array?.isArray(res?.data)) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "error",
                                message: res?.data[0],
                            },
                        });
                        setLoading("init");
                    } else {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: "Files are ingesting to the database, Please wait to complete the process.",
                            },
                        });
                        setLoading("uploaded");
                        setResponse(res?.data?.response);
                        Api?.getClaimsExistingProjects({ user_id: contextStore?.profile?.userId })?.then((claim: any) => {
                            if (claim?.status === 200) {
                                setContext({
                                    ...contextStore,
                                    claims: { ...contextStore?.claims, projects: claim?.data?.projects },
                                });
                            }
                        });
                    }
                } else {
                    setLoading("init");
                }
            })
            ?.catch((err) => setLoading("init"));
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "68vh" }}>
            <Box sx={{ flex: 1, overflowY: "scroll", px: 3 }}>
                {uploadFiles?.map((item: any) => (
                    <>
                        <UploadProgess
                            response={response}
                            item={item}
                            loading={loading}
                            socketHooks={socketHooks}
                            msgRef={msgRef}
                            setUploadFiles={setUploadFiles}
                        />
                        <Divider />
                    </>
                ))}
            </Box>
            <Divider />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1,
                    pt: 1.5,
                }}>
                <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}></Stack>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 0.7 }}>
                    <Stack direction={"row"}>
                        <Button
                            sx={{ fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem", color: "#3E3E3E" }}
                            onClick={() => {
                                setUploadFiles([]);
                            }}>
                            Go back
                        </Button>
                    </Stack>
                    {["init"]?.includes(loading) && (
                        <Stack direction={"row"}>
                            <Button
                                sx={{
                                    fontFamily: "Gilroy-SemiBold",
                                    fontSize: "0.85rem",
                                    color: "#fff",
                                    background: "#007BFF",
                                    "&:hover": {
                                        color: "#fff",
                                        background: "#007BFF",
                                    },
                                    "&:disabled": {
                                        color: "#c2c2c2",
                                        background: "#efefef",
                                    },
                                    px: 3,
                                }}
                                onClick={() => handleUploadFreshFiles()}>
                                Upload
                            </Button>
                        </Stack>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default UploadingFiles;
