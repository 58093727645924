import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../Screens/Auth/Loading";

const PublicRoute = ({ children }: any) => {
    const { isAuthenticated, isLoading, user } = useAuth0();

    if (isLoading) return <Loading />;

    // if (isAuthenticated && !user?.email_verified) return <Navigate to="/verify" replace />;

    if (isAuthenticated && user?.email_verified) return <Navigate to="/" replace />;

    return children;
};

export default PublicRoute;
