import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import ProfileAvatar from "../../../Icons/ProfileAvatar";
import LogoXIcon from "../../../Icons/LogoXIcon";
import Typing from "../../Loader/Typing";

const MessageBox = ({ elem, msgRef, setMsgList, lastElement, scrollRef }: any) => {
    const theme = useTheme();
    const [message, setMessage] = useState<any>();
    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (typeof elem?.msg === "object" && elem?.msg?.response?.length && lastElement && elem?.generated === "self") {
            timeout = setTimeout(() => {
                setMessage(() => {
                    return elem?.msg;
                });
            }, 20);
        } else {
            setMessage(() => {
                scrollRef?.current?.lastElementChild?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
                return elem?.msg;
            });
        }
        return () => clearTimeout(timeout);
    }, [elem?.msg]);

    useEffect(() => {
        if (msgRef?.hasOwnProperty("load")) {
            setLoading(() => msgRef?.load);
        }
    }, [msgRef?.load]);

    const styles = () => {
        if (smd) {
            return {
                main: {
                    px: 2,
                    ml: elem?.out ? 1 : 0,
                },
            };
        }
        return {};
    };

    const handleTerminateResponse = () => {
        msgRef.load = false;
        setMsgList((prev: any) => {
            if (prev?.latest) {
                prev.latest = false;
                prev.color = "#C55";
                prev.msg = "Response Terminated.";
                prev.error = true;
            }
            return prev;
        });
    };

    return (
        <Box
            sx={{
                width: "85%",
                display: "flex",
                flexDirection: "column",
                background: elem?.in ? "#fff" : "",
                py: 2,
                border: elem?.in ? "1px solid #F2F2F2" : "",
                borderRadius: elem?.in ? "10px" : "",
                position: "relative",
                ...styles()?.main,
            }}>
            <Box sx={{ display: "flex", gap: 2, px: 2.5, alignItems: elem?.out ? "center" : "flex-start" }}>
                <Box sx={{ pt: 0.5 }}>{elem?.out ? <ProfileAvatar /> : <LogoXIcon />}</Box>
                <Box sx={{ width: "100%" }}>
                    {lastElement && loading && <Typing onClick={handleTerminateResponse} />}
                    <Typography sx={{ fontSize: "0.9rem", color: elem?.error ? elem?.color : "inherit" }}>
                        {typeof message?.response === "string"
                            ? message?.response
                                  ?.replaceAll(/[eE]\+\d{2}|\*/g, "")
                                  ?.split("\n")
                                  ?.map((msg: string) => (
                                      <>
                                          {msg}
                                          <br />
                                      </>
                                  ))
                            : message}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MessageBox;
