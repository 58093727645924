import React from "react";

const Collapse = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.66797 8.25033H18.3346V10.0837H3.66797V8.25033ZM14.668 3.66699H11.918V0.916992H10.0846V3.66699H7.33464L11.0013 7.33366L14.668 3.66699ZM7.33464 17.417H10.0846V20.167H11.918V17.417H14.668L11.0013 13.7503L7.33464 17.417ZM3.66797 11.0003H18.3346V12.8337H3.66797V11.0003Z"
                fill="black"
            />
        </svg>
    );
};

export default Collapse;
