import { Box, FormControl, InputLabel, Select, MenuItem, Button, Stack, TableRow, TableCell, Table } from "@mui/material";
import React, { useContext, useState } from "react";
import { Api } from "../../../apis";
import { Context } from "../../../store/store";

const Filters = ({
    tabSelect,
    setLoader,
    setFilterStatus,
    setTableData,
    loader,
}: {
    tabSelect: "chronology" | "clauses_result" | "de_period_result";
    setLoader: (value: boolean) => void;
    setFilterStatus: (value: boolean) => void;
    setTableData: (value: any[]) => void;
    loader: boolean;
}) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [filters, setFilters] = useState<any>({
        package: "C4",
        contractor: "L&T",
        eot: "EOT-3",
        party: "",
        delayEvent: "",
        delayEventPause: false,
        responseTime: "",
        sub_delayEvent: [],
        sub_delay: "",
        filtered: false,
    });
    const [letterCount, setLetterCount] = useState<any>({});
    const delay_event = [
        "",
        "DE-01",
        "DE-1A",
        "DE-02",
        "DE-03",
        "DE-04",
        "DE-05",
        "DE-06",
        "DE-07",
        "DE-08",
        "DE-09",
        "DE-10",
        "DE-11",
        "DE-12",
        "DE-13",
        "DE-14",
        "DE-15",
        "DE-16",
        "DE-17",
        "DE-18",
        "DE-19",
        "DE-20",
        "DE-21",
        "DE-22",
        "DE-22A",
        "DE-23",
        "DE-24",
        "DE-24A",
        "DE-24B",
        "DE-25",
        "DE-26",
        "DE-27",
        "DE-28",
        "DE-29",
        "DE-30",
        "DE-31",
        "DE-32",
        "DE-33",
        "DE-34",
        "DE-35",
        "DE-36",
        "DE-37",
        "DE-38",
        "DE-39",
        "DE-40",
        "DE-41",
        "DE-42",
        "DE-43",
        "DE-44",
        "DE-45",
        "DE-46",
        "DE-47",
        "DE-48",
        "DE-49",
        "DE-50",
        "DE-51",
        "DE-52",
        "DE-53",
        "DE-54",
        "DE-55",
        "DE-56",
        "DE-57",
        "DE-58",
        "DE-59",
        "DE-60",
        "DE-61",
        "DE-62",
        "DE-63",
    ];

    const handleFilter = () => {
        setLoader(true);
        setFilterStatus(true);
        setTableData([]);
        let filterPayload = {};
        if (tabSelect === "chronology") {
            if (filters?.delayEvent && !filters?.filtered) fetchSubDelayEvent(filters?.delayEvent);
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {
                    ...(filters?.party ? { Party: filters?.party } : {}),
                    ...(filters?.delayEvent ? { "Delay Event": filters?.delayEvent } : {}),
                    ...(filters?.responseTime ? { "Response Time": filters?.responseTime } : {}),
                    ...(filters?.sub_delay ? { "Sub Delay Event": filters?.sub_delay } : {}),
                },
            };
        }
        if (tabSelect === "de_period_result")
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {
                    ...(filters?.delayEvent ? { "Delay Event": filters?.delayEvent } : {}),
                },
            };
        if (tabSelect === "clauses_result")
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {},
            };
        Api?.getClaimsExistingDelayEvent({ user_id: contextStore?.profile?.userId, ...filterPayload })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setFilters((prev: any) => ({ ...prev, filtered: true }));
                    if (tabSelect === "chronology") {
                        setTableData(JSON?.parse(res?.data?.chronology));
                        fetchLetterCount();
                    }
                    if (tabSelect === "de_period_result") setTableData(JSON?.parse(res?.data?.de_period_result));
                    if (tabSelect === "clauses_result") setTableData(JSON?.parse(res?.data?.clauses_result));
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    const fetchSubDelayEvent = (value: string) => {
        Api?.getSubDelayEvent({ delay_event: value, user_id: contextStore?.profile?.userId })
            ?.then((res: any) => {
                let data: any[] = [];
                if (res?.status === 200 && typeof res?.data === "object") {
                    data = res?.data?.sub_delay_event?.map((item: any) => item?.toString() || "null");
                }
                setFilters((prev: any) => {
                    return { ...prev, delayEventPause: false, sub_delayEvent: data };
                });
            })
            ?.catch((err) => {
                setFilters((prev: any) => {
                    return { ...prev, delayEventPause: false };
                });
            });
    };

    const fetchLetterCount = () => {
        Api?.getLetterCount()?.then((res: any) => {
            if (res?.status === 200) {
                setLetterCount(res?.data);
            }
        });
    };

    return (
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ py: 1.5, px: 2, borderBottom: 1, borderColor: "divider" }}>
            <Stack gap={1.8}>
                <Stack direction={"row"} gap={1.8}>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Package</InputLabel>
                        <Select
                            value={filters?.package}
                            onChange={(e: { target: { value: string } }) => setFilters((prev: any) => ({ ...prev, package: e?.target?.value }))}
                            label={"Package"}
                            size="small"
                            sx={{ minWidth: "8rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                            <MenuItem value={"C4"}>C4</MenuItem>
                        </Select>
                    </FormControl>
                    {["chronology"]?.includes(tabSelect) && (
                        <FormControl size="small">
                            <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Party</InputLabel>
                            <Select
                                value={filters?.party}
                                onChange={(e: { target: { value: string } }) => setFilters((prev: any) => ({ ...prev, party: e?.target?.value }))}
                                size="small"
                                label="Party"
                                sx={{ minWidth: "8rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                                {["", "NHSRCL", "LTC", "TCAP"]?.map((item) =>
                                    item === "" ? (
                                        <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                            N/A
                                        </MenuItem>
                                    ) : (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    )}
                </Stack>

                <Stack direction={"row"} gap={1.8}>
                    {["chronology", "de_period_result"]?.includes(tabSelect) && (
                        <FormControl size="small">
                            <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Delay Event</InputLabel>
                            <Select
                                disabled={filters?.delayEventPause}
                                value={filters?.delayEvent}
                                onChange={(e: { target: { value: string } }) => {
                                    setFilters((prev: any) => {
                                        let event: boolean = false;
                                        let sub_delay: string = prev?.sub_delay;
                                        if (!prev?.delayEventPause && prev?.filtered) {
                                            fetchSubDelayEvent(e?.target?.value);
                                            event = true;
                                            sub_delay = "";
                                        }
                                        return { ...prev, delayEvent: e?.target?.value, delayEventPause: event, sub_delay };
                                    });
                                }}
                                size="small"
                                label="Delay Event"
                                sx={{ minWidth: "8rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                                {delay_event?.map((item) =>
                                    item === "" ? (
                                        <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                            N/A
                                        </MenuItem>
                                    ) : (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    )}
                    {["chronology"]?.includes(tabSelect) && (
                        <FormControl size="small">
                            <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Sub Delay Event</InputLabel>
                            <Select
                                disabled={!filters?.filtered}
                                value={filters?.sub_delay || ""}
                                onChange={(e: { target: { value: string } }) => setFilters((prev: any) => ({ ...prev, sub_delay: e?.target?.value }))}
                                size="small"
                                label="Sub Delay Event"
                                sx={{ minWidth: "8rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                                {filters?.sub_delayEvent?.map((item: any) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Stack>
            </Stack>
            <Stack gap={1.8}>
                <Stack direction={"row"} gap={1.8} justifyContent={"flex-end"}>
                    <FormControl size="small">
                        <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>EOT</InputLabel>
                        <Select
                            value={filters?.eot}
                            onChange={(e: { target: { value: string } }) => setFilters((prev: any) => ({ ...prev, eot: e?.target?.value }))}
                            size="small"
                            label="EOT"
                            sx={{ minWidth: "7rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                            <MenuItem value={"EOT-3"}>EOT-3</MenuItem>
                        </Select>
                    </FormControl>
                    {["chronology"]?.includes(tabSelect) && (
                        <FormControl size="small">
                            <InputLabel sx={{ top: "-3px", fontSize: "0.9rem" }}>Response Time</InputLabel>
                            <Select
                                value={filters?.responseTime}
                                onChange={(e: { target: { value: string } }) =>
                                    setFilters((prev: any) => ({ ...prev, responseTime: e?.target?.value }))
                                }
                                size="small"
                                label="Response Time"
                                sx={{ minWidth: "8.9rem", fontSize: "0.9rem", fontFamily: "Gilroy-Medium", height: "2rem" }}>
                                {["", ">=21", "<21"]?.map((item) =>
                                    item === "" ? (
                                        <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                            N/A
                                        </MenuItem>
                                    ) : (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        sx={{
                            minWidth: "7rem",
                            height: "2rem",
                            background: "#007BFF",
                            color: "#fff",
                            transition: "ease-in-out 80ms",
                            letterSpacing: "0.5px",
                            "&:hover": {
                                background: "#007BFFaa",
                                color: "#fff",
                            },
                        }}
                        onClick={handleFilter}
                        disabled={loader}>
                        Apply
                    </Button>
                </Stack>
                <Stack>
                    {["chronology"]?.includes(tabSelect) && (
                        <Table>
                            <TableRow>
                                <TableCell sx={{ border: "1px solid #D9D9D9", background: "#E3F4FF" }} size="small">
                                    Document
                                </TableCell>
                                <TableCell sx={{ border: "1px solid #D9D9D9", background: "#E3F4FF" }} size="small">
                                    Contractor
                                </TableCell>
                                <TableCell sx={{ border: "1px solid #D9D9D9", background: "#E3F4FF" }} size="small">
                                    Engineer
                                </TableCell>
                                <TableCell sx={{ border: "1px solid #D9D9D9", background: "#E3F4FF" }} size="small">
                                    Employer
                                </TableCell>
                            </TableRow>
                            {[
                                { Document: "Letters", ...(Object?.keys(letterCount)?.length > 0 ? letterCount : {}) },
                                { Document: "SRR’s", Contractor: "--", Engineer: "--", Employer: "--" },
                                { Document: "Emails", Contractor: "--", Engineer: "--", Employer: "--" },
                            ]?.map((row: any) => (
                                <TableRow>
                                    <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", py: "5px" }}>
                                        {row["Document"]}
                                    </TableCell>
                                    <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", py: "5px" }}>
                                        {row["Contractor"]}
                                    </TableCell>
                                    <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", py: "5px" }}>
                                        {row["Engineer"]}
                                    </TableCell>
                                    <TableCell size="small" sx={{ border: "1px solid #D9D9D9", fontSize: "0.8rem", py: "5px" }}>
                                        {row["Employer"]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Filters;
