import React from "react";

const Folder = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_10096_2173" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                <rect width="17.7273" height="17.7273" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10096_2173)">
                <path
                    d="M2.21555 15.5126C1.8093 15.5126 1.46153 15.368 1.17223 15.0787C0.882931 14.7894 0.738281 14.4416 0.738281 14.0353V4.43306H2.21555V14.0353H14.7724V15.5126H2.21555ZM5.1701 12.5581C4.76385 12.5581 4.41607 12.4134 4.12678 12.1241C3.83748 11.8348 3.69283 11.487 3.69283 11.0808V2.95579C3.69283 2.54954 3.83748 2.20176 4.12678 1.91246C4.41607 1.62317 4.76385 1.47852 5.1701 1.47852H8.86328L10.3406 2.95579H15.511C15.9173 2.95579 16.265 3.10044 16.5543 3.38974C16.8436 3.67904 16.9883 4.02681 16.9883 4.43306V11.0808C16.9883 11.487 16.8436 11.8348 16.5543 12.1241C16.265 12.4134 15.9173 12.5581 15.511 12.5581H5.1701ZM5.1701 11.0808H15.511V4.43306H9.73118L8.25391 2.95579H5.1701V11.0808Z"
                    fill="#1C1B1F"
                />
            </g>
        </svg>
    );
};

export default Folder;
